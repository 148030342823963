import {Injectable} from "@angular/core";
import * as HighchartsStock from "highcharts/highstock";
import * as Highcharts from "highcharts";


@Injectable({
    providedIn: "root",
})
export class HighchartsConfigService {
    private lang = {
        downloadCSV: $localize`Download CSV`,
        downloadJPEG: $localize`Download JPEG image`,
        downloadPDF: $localize`Download PDF document`,
        downloadPNG: $localize`Download PNG image`,
        downloadSVG: $localize`Download SVG vector image`,
        downloadXLS: $localize`Download XLS`,
        exitFullscreen: $localize`Exit from full screen`,
        hideData: $localize`Hide data table`,
        loading: $localize`Loading...`,
        noData: $localize`No data to display`,
        printChart: $localize`Print chart`,
        resetZoom: $localize`Reset zoom`,
        viewData: $localize`View data table`,
        viewFullscreen: $localize`View in full screen`,
        months: [
            $localize`January`,
            $localize`February`,
            $localize`March`,
            $localize`April`,
            $localize`May`,
            $localize`June`,
            $localize`July`,
            $localize`August`,
            $localize`September`,
            $localize`October`,
            $localize`November`,
            $localize`December`,
        ],
        shortMonths: [
            $localize`Jan`,
            $localize`Feb`,
            $localize`Mar`,
            $localize`Apr`,
            $localize`May`,
            $localize`Jun`,
            $localize`Jul`,
            $localize`Aug`,
            $localize`Sep`,
            $localize`Oct`,
            $localize`Nov`,
            $localize`Dec`,
        ],
        weekdays: [
            $localize`Sunday`,
            $localize`Monday`,
            $localize`Tuesday`,
            $localize`Wednesday`,
            $localize`Thursday`,
            $localize`Friday`,
            $localize`Saturday`,
        ],
        rangeSelectorZoom: $localize`Zoom`,
    };

    constructor() {
        this.setGlobalHighchartsOptions();
    }

    setGlobalHighchartsOptions(): void {
        HighchartsStock.setOptions({
            lang: this.lang,
            credits: {
                enabled: true, // Or false to disable
                href: "https://amudar.io",
                text: "amudar.io",
                style: {
                    fontSize: "10px",
                    color: "#666666",
                },
                position: {
                    align: "right", // Horizontal alignment (left, center, right)
                    verticalAlign: "bottom", // Vertical alignment (top, middle, bottom)
                    x: -10, // Horizontal offset
                    y: -10, // Vertical offset
                },
            },
        });
        Highcharts.setOptions({
            lang: this.lang,
            credits: {
                enabled: true, // Or false to disable
                href: "https://amudar.io",
                text: "amudar.io",
                style: {
                    fontSize: "10px",
                    color: "#666666",
                },
                position: {
                    align: "right", // Horizontal alignment (left, center, right)
                    verticalAlign: "bottom", // Vertical alignment (top, middle, bottom)
                    x: -10, // Horizontal offset
                    y: -10, // Vertical offset
                },
            },
        });
    }
}
