import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "round",
})
export class RoundPipe implements PipeTransform {
  transform(value: number, fraction: number): string {
    if (value || value === 0) {
      return value.toFixed(fraction);
    }
    return "";
  }
}
