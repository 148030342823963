import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class HexToRGBService {
  constructor() {}

  public findColor(start, end, t) {
    start = this.cssToRgb(start);
    end = this.cssToRgb(end);

    // x = (1-t)*start + t*end
    const result = this.addVectors(
      this.multiplyVectorByScalar(1 - t, start),
      this.multiplyVectorByScalar(t, end)
    );
    return this.rgbToCss(result);
  }

  private multiplyVectorByScalar(scalar, vector) {
    var result = [];
    for (var i = 0; i < vector.length; i++) {
      result.push(scalar * vector[i]);
    }
    return result;
  }

  private addVectors(v1, v2) {
    if (v1.length !== v2.length) {
      throw new Error("Vectors must have the same length");
    }
    var result = [];
    for (var i = 0; i < v1.length; i++) {
      result.push(v1[i] + v2[i]);
    }
    return result;
  }

  private cssToRgb(cssColor) {
    // Remove the leading '#' if it exists
    if (cssColor[0] === "#") {
      cssColor = cssColor.slice(1);
    }

    // Convert the color to a 3- or 6-digit hexadecimal value if necessary
    if (cssColor.length === 3) {
      cssColor =
        cssColor[0] +
        cssColor[0] +
        cssColor[1] +
        cssColor[1] +
        cssColor[2] +
        cssColor[2];
    } else if (cssColor.length !== 6) {
      throw new Error("Invalid CSS color: " + cssColor);
    }

    // Convert the hexadecimal value to RGB values
    var r = parseInt(cssColor.slice(0, 2), 16);
    var g = parseInt(cssColor.slice(2, 4), 16);
    var b = parseInt(cssColor.slice(4, 6), 16);

    // Return the RGB values as a tuple of decimals
    return [r, g, b];
  }

  private rgbToCss(rgb) {
    // Convert the RGB values to hexadecimal values
    var rHex = Math.round(rgb[0]).toString(16).padStart(2, "0");
    var gHex = Math.round(rgb[1]).toString(16).padStart(2, "0");
    var bHex = Math.round(rgb[2]).toString(16).padStart(2, "0");

    // Combine the hexadecimal values into a CSS color string
    return "#" + rHex + gHex + bHex;
  }
}
