import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loadingSubject = new BehaviorSubject<boolean>(false)
  isLoading$ = this.loadingSubject.asObservable()
  private loadingCount = 0
  show() {
    this.loadingCount++
    this.loadingSubject.next(this.loadingCount > 0)
  }

  hide() {
    this.loadingCount--
    this.loadingSubject.next(this.loadingCount > 0)
  }

  reset() {
    this.loadingCount = 0
    this.loadingSubject.next(this.loadingCount > 0)
  }
}
