import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  LOCALE_ID,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  MeteostationsQuery,
  MeteostationsService,
  MeteostationsStore,
} from "../../../../store/meteostations/state";
import { HeaderService } from "../../../../pages/meteostations/dashboard/header/header.service";
import * as L from "leaflet";
import { LatLng, LatLngBounds, LatLngTuple } from "leaflet";
import { Parameter } from "../../../../shared/meteostations/meteostation-parameters";
import { Subscription } from "rxjs";
import { Device } from "../../../../@core/interfaces/common/device";
import { NbDialogRef } from "@nebular/theme";
import { StateHistoryService } from "../../../../store/meteostations/state/state-history.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "ngx-city-map",
  templateUrl: "./city-map.component.html",
  styleUrls: ["./city-map.component.scss"],
})
export class CityMapComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("mapMeteoWidget", { static: false }) mapWidget: ElementRef;
  public markerClusterGroup: L.MarkerClusterGroup;
  public mapOpened = true;
  public parameters: Parameter[];
  private parameterValues$: Subscription;
  private map: L.Map = null;
  private devices: Device[] = [];
  private devices$: Subscription;
  private bounds: LatLngBounds;
  private currentDevice$: Subscription;
  private currentDevice: Device;
  private zoom = 15;
  private initialCoords: LatLngTuple = [41.3710955885187, 69.206900484656];
  public workingDvcGroup: L.LayerGroup;
  public notWorkingDvcGroup: L.LayerGroup;
  public possiblyNotWorkingDvcGroup: L.LayerGroup;
  private words = {
    deviceInfo: $localize`Device Info`,
    deviceName: $localize`Device Name`,
    deviceSerial: $localize`Device Serial`,
    address: $localize`Address`,
    lastSignal: $localize`Last Signal`,
    battery: $localize`Battery`,
    volt: $localize`Volt`,
  };

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    public meteostationsQuery: MeteostationsQuery,
    private stateHistoryService: StateHistoryService,
    private meteostationsStore: MeteostationsStore,
    private zone: NgZone,
    private chd: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    protected dialogRef: NbDialogRef<CityMapComponent>
  ) {}

  ngOnInit(): void {
    this.devices$ = this.meteostationsQuery
      .select("meteostations")
      .subscribe((devices) => {
        this.devices = devices;
        if (devices.length > 0 && this.map) {
          this.zone.runOutsideAngular(() => {
            this.refreshMap();
          });
        }
      });

    this.currentDevice$ = this.meteostationsQuery
      .select("currentMeteostation")
      .subscribe((data) => {
        this.currentDevice = data;
        if (this.map && this.currentDevice) {
          this.zone.runOutsideAngular(() => {
            this.map.setView(
              new LatLng(
                data.geolocation.coordinates[0],
                data.geolocation.coordinates[1]
              ),
              this.zoom
            );
          });
        }
      });
  }

  public ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      this.initMap();
      if (this.devices.length > 0) {
        this.refreshMap();
        this.resetMap();
      }
    });
  }

  public ngOnDestroy(): void {
    this.devices = [];
    this.devices$?.unsubscribe();
    this.currentDevice$?.unsubscribe();
    this.parameterValues$?.unsubscribe();
  }

  public refreshView(): void {
    this.map.fitBounds(this.bounds);
    this.map.invalidateSize();
  }

  public resetMap() {
    this.refreshView();
    this.mapOpened = true;
  }

  public close() {
    this.dialogRef.close();
  }

  private initMap(): void {
    this.map = L.map(this.mapWidget.nativeElement, {
      zoom: this.zoom,
      markerZoomAnimation: false,
      scrollWheelZoom: false,
      preferCanvas: true,
      renderer: new L.Canvas(),
    }).setView(this.initialCoords, this.zoom);
    if (this.currentDevice) {
      this.map.setView(
        new LatLng(
          this.currentDevice.geolocation.coordinates[0],
          this.currentDevice.geolocation.coordinates[1]
        ),
        this.zoom
      );
    }
  }

  private refreshMap(): void {
    const tiles = L.tileLayer(
      "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      {
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        minZoom: 5,
      }
    );
    tiles.addTo(this.map);
    const lats = [];
    const workingDevices = [];
    const notWorkingDevices = [];
    const possiblyNotWorkingDevices = [];
    const greenStation = "./assets/images/map/meteostation_green.png";
    const yellowStation = "./assets/images/map/meteostation_yellow.png";
    const redStation = "./assets/images/map/meteostation_red.png";
    for (const device of this.devices) {
      const diffTime =
        (new Date().getTime() - new Date(device.last_signal).getTime()) /
        (1000 * 60 * 60);
      let marker;
      if (diffTime < 6) {
        marker = this.generateMarker(device, greenStation);
        workingDevices.push(marker);
      } else if (diffTime < 12) {
        marker = this.generateMarker(device, yellowStation);
        possiblyNotWorkingDevices.push(marker);
      } else {
        marker = this.generateMarker(device, redStation);
        notWorkingDevices.push(marker);
      }
      lats.push(
        new LatLng(
          device.geolocation.coordinates[0],
          device.geolocation.coordinates[1]
        )
      );
    }
    this.workingDvcGroup = L.layerGroup(workingDevices);
    this.notWorkingDvcGroup = L.layerGroup(notWorkingDevices);
    this.possiblyNotWorkingDvcGroup = L.layerGroup(possiblyNotWorkingDevices);
    this.markerClusterGroup = L.markerClusterGroup({
      removeOutsideVisibleBounds: true,
      maxClusterRadius: 60,
      showCoverageOnHover: false,
      iconCreateFunction: function (cluster) {
        return L.divIcon({
          html:
            "<div class='marker-cluster-default'><div><span>" +
            cluster.getChildCount() +
            "</span></div></div>",
          className: "marker-cluster",
        });
      },
    })
      .addLayers([
        ...this.workingDvcGroup.getLayers(),
        ...this.possiblyNotWorkingDvcGroup.getLayers(),
        ...this.notWorkingDvcGroup.getLayers(),
      ])
      .addTo(this.map);
    this.bounds = new L.LatLngBounds(lats);
    this.map.fitBounds(this.bounds);
    this.resetMap();
  }

  private generateMarker(device, image) {
    const imageElement = `
            <img src=${image}
                style="
                width: 30px;
                height: 30px;
                " alt="trap"
                />`;
    const icon = L.divIcon({
      html: `
            <div>
            ${imageElement}
            </div>
            `,
      className: "myIcon",
      iconSize: [30, 30],
    });
    return L.marker(
      new LatLng(
        device.geolocation.coordinates[0],
        device.geolocation.coordinates[1]
      ),
      {
        icon: icon,
      }
    )
      .on("click", (e) => {
        this.changeDevice(device);
        this.close();
      })
      .bindPopup(this.getPopupContent(device));
  }

  private getPopupContent(device: Device): string {
    return `
          <h6>${this.words.deviceInfo}</h6>
          <table>
                <tr><td>${this.words.deviceName}:</td><td>${
      device.name
    }</td></tr>
                <tr><td>${this.words.deviceSerial}:</td><td>${
      device.serial_number
    }</td></tr>
                <tr><td>${this.words.address}:</td><td>${
      device.address
    }</td></tr>
                <tr><td>${this.words.lastSignal}:</td><td>${
      device.last_signal_human
    }</td></tr>
                <tr><td>${this.words.battery}:</td><td>${
      device.battery ? device.battery.toFixed(1) : 0
    } <span>${$localize`Volt`}</span></td></tr>
            </table>
          `;
  }

  private changeDevice(device: Device) {
    const stateDetails = this.stateHistoryService.getStateDetails(
      {
        startDate: "-3d",
        endDate: "now()",
        refresh: "1h",
      },
      device
    );
    const stateIndex = stateDetails.index;
    const stateTime = stateDetails.time;
    this.map.setView(
      new LatLng(
        device.geolocation.coordinates[0],
        device.geolocation.coordinates[1]
      ),
      this.zoom
    );

    const currentMeteostation = this.meteostationsQuery
      .getValue()
      .meteostations.find((d) => d.serial_number === device.serial_number);
    if (stateIndex === -1) {
      if (this.currentDevice.serial_number !== device.serial_number) {
        this.meteostationsStore.update({ currentMeteostation });
        const snapshotMap = this.activatedRoute.snapshot.queryParamMap;
        const fragment = this.activatedRoute.snapshot.fragment;
        const queryParams = {
          organizationId: snapshotMap.get("organizationId"),
          stationId: currentMeteostation.serial_number,
          startDate: snapshotMap.get("startDate"),
          endDate: snapshotMap.get("endDate"),
          refresh: snapshotMap.get("refresh"),
          shared: '1'
        };

        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: queryParams,
          fragment: fragment
        })
      }
    } else {
      this.stateHistoryService.changeState(stateTime, stateIndex);
    }
  
    
    this.chd.detectChanges();
  }
}
