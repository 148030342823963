import {Injectable} from "@angular/core";
import * as XLSX from "xlsx";
import {saveAs} from "file-saver";

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor(
  ) {

  }

  public exportJSONToExcel(jsonData: any[], fileName = 'data'): void {
    // Create a new workbook
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Data');

    // Save the workbook as an Excel file
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, fileName);
  }

  public exportTableIdToExcel(tableId: string, fileName = 'data'): void {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(document.getElementById(tableId));
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, fileName);
  }

  public exportCSVToExcel(csv: string, fileName = 'data'): void {
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(csv.split('\n').map(row => row.split(',')));
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, fileName);
  }

  public exportTableToExcel(table: HTMLTableElement, fileName = 'data'): void {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, fileName);
  }




  private saveAsExcelFile(buffer: any, fileName: string): void {
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob, `${fileName}.xlsx`);
  }
}
