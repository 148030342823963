import {ChangeDetectionStrategy, Component, Input, OnInit} from "@angular/core";
import { HelperService } from "../../../@core/utils/helper.service";

@Component({
  selector: "ngx-wind-direction",
  template: `<i class="{{ direction }}"></i> `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WindDirectionComponent implements OnInit {
  @Input() degree: number;
  public direction: string;
  constructor(private helperService: HelperService) {}

  ngOnInit(): void {
    this.direction = this.helperService.getWindDirections(this.degree);
  }
}
