<div class="map-container w-100 my-1">
    <p-panel>
        <ng-template pTemplate="header" style="padding: 0">
            <div class="header d-flex p-0 w-100 justify-content-between align-items-center">
                <h4 i18n>Map</h4>
                <div class="control-buttons d-flex">
                    <div class="d-flex justify-content-end mx-3">
                        <div class="button-wrapper" *ngIf="greenDvcGroup">
                            <button #button_green
                                    class="btn btn-secondary d-flex align-items-center"
                                    (click)="$event.stopPropagation(); toggleGroup(greenDvcGroup, button_green)">
                                <img class="mx-1" src="../../../../../assets/images/map/meteostation_green.png" alt="">
                                <span>{{greenDvcGroup.getLayers().length}}</span>
                            </button>
                        </div>
                        <div class="button-wrapper" *ngIf="yellowDvcGroup">
                            <button #button_yellow
                                    class="btn btn-secondary d-flex align-items-center"
                                    (click)="$event.stopPropagation(); toggleGroup(yellowDvcGroup, button_yellow)">
                                <img class="mx-1" src="../../../../../assets/images/map/meteostation_yellow.png" alt="">
                                <span>{{yellowDvcGroup.getLayers().length}}</span>
                            </button>
                        </div>
                        <div class="button-wrapper" *ngIf="redDvcGroup">
                            <button #button_red
                                    class="btn btn-secondary d-flex align-items-center"
                                    (click)="$event.stopPropagation(); toggleGroup(redDvcGroup, button_red)">
                                <img class="mx-1" src="../../../../../assets/images/map/meteostation_red.png" alt="">
                                <span>{{redDvcGroup.getLayers().length}}</span>
                            </button>
                        </div>
                    </div>
                    <div class="me-2">
                        <select style="max-height: 30px; font-size: 14px"
                                class="py-1 ps-2 pe-5 form-select"
                                name="parameter"
                                [(ngModel)]="selectedParameter"
                                (change)="onParameterChange(selectedParameter)"
                                (click)="$event.stopPropagation()">
                            <option *ngFor="let parameter of parametersArr"
                                    value="{{parameter.field}}">{{parameter.caption}}</option>
                        </select>
                    </div>
                    <div class="mobile-hidden">
                        <button class="btn btn-outline-secondary px-2 py-1"
                                (click)="$event.stopPropagation(); resetMap()" i18n>Show all</button>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="content">
            <div class="body" [style]="{width: '100%', height: 'auto'}">
                <div class="map-widget-wrapper" #mapMeteoWidget style="min-width: 250px; height: auto"></div>
            </div>
        </ng-template>
    </p-panel>
</div>
