import { Injectable } from "@angular/core";
import { AqiDescriptionModel } from "../models/aqi-description.model";

@Injectable({
  providedIn: "root",
})
export class ComputingAQIService {
  private readonly range_for_pm1 = {
    good: {
      cLow: 0,
      cHigh: 10,
      description: $localize`Good`,
    },
    moderate: {
      cLow: 10.1,
      cHigh: 30.4,
      description: $localize`Moderate`,
    },
    unhealthyForSG: {
      cLow: 30.5,
      cHigh: 50.4,
      description: $localize`Unhealthy for Sensitive Groups`,
    },
    unhealthy: {
      cLow: 50.5,
      cHigh: 140.4,
      description: $localize`Unhealthy`,
    },
    veryUnhealthy: {
      cLow: 140.5,
      cHigh: 200.4,
      description: $localize`Very Unhealthy`,
    },
    hazardous: {
      cLow: 200.5,
      cHigh: 400.4,
      description: $localize`Hazardous`,
    },
  };
  private readonly range_for_pm25 = {
    good: {
      cLow: 0,
      cHigh: 12,
      description: $localize`Good`,
    },
    moderate: {
      cLow: 12.1,
      cHigh: 35.4,
      description: $localize`Moderate`,
    },
    unhealthyForSG: {
      cLow: 35.5,
      cHigh: 55.4,
      description: $localize`Unhealthy for Sensitive Groups`,
    },
    unhealthy: {
      cLow: 55.5,
      cHigh: 150.4,
      description: $localize`Unhealthy`,
    },
    veryUnhealthy: {
      cLow: 150.5,
      cHigh: 250.4,
      description: $localize`Very Unhealthy`,
    },
    hazardous: {
      cLow: 250.5,
      cHigh: 500.4,
      description: $localize`Hazardous`,
    },
  };
  private readonly range_for_pm10 = {
    good: {
      cLow: 0,
      cHigh: 54,
      description: $localize`Good`,
    },
    moderate: {
      cLow: 55,
      cHigh: 154,
      description: $localize`Moderate`,
    },
    unhealthyForSG: {
      cLow: 155,
      cHigh: 254,
      description: $localize`Unhealthy for Sensitive Groups`,
    },
    unhealthy: {
      cLow: 255,
      cHigh: 354,
      description: $localize`Unhealthy`,
    },
    veryUnhealthy: {
      cLow: 355,
      cHigh: 424,
      description: $localize`Very Unhealthy`,
    },
    hazardous: {
      cLow: 425,
      cHigh: 604,
      description: $localize`Hazardous`,
    },
  };
  public readonly breakpoints = {
    "PM1.0": this.range_for_pm1,
    "PM2.5": this.range_for_pm25,
    PM10: this.range_for_pm10,
    AlphaPM1_0: this.range_for_pm1,
    AlphaPM2_5: this.range_for_pm25,
    AlphaPM10: this.range_for_pm10,
    RikaPM1_0: this.range_for_pm1,
    RikaPM2_5: this.range_for_pm25,
    RikaPM10: this.range_for_pm10,
    NovaPM2_5: this.range_for_pm25,
    NovaPM10: this.range_for_pm10,
    AQI: {
      good: {
        description: $localize`Good`,
        description_full: $localize`Enjoy your usual outdoor activities.`,
        img: "ic-face-green.svg",
        backgroundColor: "#90ee92",
        fontColor: "#607631",
        iLow: 0,
        iHigh: 50,
      },
      moderate: {
        description: $localize`Moderate`,
        description_full: $localize`May cause minor breathing discomfort to sensitive people.`,
        img: "ic-face-yellow.svg",
        backgroundColor: "rgba(255,242,71,0.8)",
        fontColor: "#8C6C1D",
        iLow: 51,
        iHigh: 100,
      },
      unhealthyForSG: {
        description: $localize`Unhealthy for Sensitive Groups`,
        description_full: $localize`May cause breathing discomfort to people with lung disease such as asthma, and discomfort to people with heart disease, children and older adults.`,
        img: "ic-face-orange.svg",
        backgroundColor: "#f5bc99",
        fontColor: "#974A20",
        iLow: 101,
        iHigh: 150,
      },
      unhealthy: {
        description: $localize`Unhealthy`,
        description_full: $localize`May cause breathing discomfort to people on prolonged exposure, and discomfort to people with heart disease.`,
        img: "ic-face-red.svg",
        backgroundColor: "rgb(239,146,146)",
        fontColor: "#942431",
        iLow: 151,
        iHigh: 200,
      },
      veryUnhealthy: {
        description: $localize`Very Unhealthy`,
        description_full: $localize`May cause respiratory illness to the people on prolonged exposure. Effect may be more pronounced in people with lung and heart diseases.`,
        img: "ic-face-purple.svg",
        backgroundColor: "#b290ee",
        fontColor: "#543B63",
        iLow: 201,
        iHigh: 300,
      },
      hazardous: {
        description: $localize`Hazardous`,
        description_full: $localize`May cause respiratory impact even on healthy people, and serious health impacts on people with lung/heart disease.`,
        img: "ic-face-maroon.svg",
        backgroundColor: "rgb(227,162,162)",
        fontColor: "#573344",
        iLow: 301,
        iHigh: 400,
      },
    },
    CO2: {
      good: {
        description: $localize`Good`,
        cLow: 0,
        cHigh: 750,
      },
      moderate: {
        description: $localize`Moderate`,
        cLow: 751,
        cHigh: 1500,
      },
      unhealthyForSG: {
        description: $localize`Unhealthy for Sensitive Groups`,
        cLow: 1501,
        cHigh: 2250,
      },
      unhealthy: {
        description: $localize`Unhealthy`,
        cLow: 2251,
        cHigh: 3000,
      },
      veryUnhealthy: {
        description: $localize`Very Unhealthy`,
        cLow: 3001,
        cHigh: 3750,
      },
      hazardous: {
        description: $localize`Hazardous`,
        cLow: 3751,
        cHigh: 5000,
      },
    },
    co: {
      good: {
        description: $localize`Good`,
        cLow: 0,
        cHigh: 4.4,
      },
      moderate: {
        description: $localize`Moderate`,
        cLow: 4.5,
        cHigh: 9.4,
      },
      unhealthyForSG: {
        description: $localize`Unhealthy for Sensitive Groups`,
        cLow: 9.5,
        cHigh: 12.4,
      },
      unhealthy: {
        description: $localize`Unhealthy`,
        cLow: 12.5,
        cHigh: 15.4,
      },
      veryUnhealthy: {
        description: $localize`Very Unhealthy`,
        cLow: 15.5,
        cHigh: 30.4,
      },
      hazardous: {
        description: $localize`Hazardous`,
        cLow: 30.5,
        cHigh: 50.4,
      },
    },
    so2: {
      good: {
        description: $localize`Good`,
        cLow: 0,
        cHigh: 35,
      },
      moderate: {
        description: $localize`Moderate`,
        cLow: 36,
        cHigh: 75,
      },
      unhealthyForSG: {
        description: $localize`Unhealthy for Sensitive Groups`,
        cLow: 76,
        cHigh: 185,
      },
      unhealthy: {
        description: $localize`Unhealthy`,
        cLow: 186,
        cHigh: 304,
      },
      veryUnhealthy: {
        description: $localize`Very Unhealthy`,
        cLow: 305,
        cHigh: 604,
      },
      hazardous: {
        description: $localize`Hazardous`,
        cLow: 605,
        cHigh: 1004,
      },
    },
    no2: {
      good: {
        description: $localize`Good`,
        cLow: 0,
        cHigh: 53,
      },
      moderate: {
        description: $localize`Moderate`,
        cLow: 54,
        cHigh: 100,
      },
      unhealthyForSG: {
        description: $localize`Unhealthy for Sensitive Groups`,
        cLow: 101,
        cHigh: 360,
      },
      unhealthy: {
        description: $localize`Unhealthy`,
        cLow: 361,
        cHigh: 649,
      },
      veryUnhealthy: {
        description: $localize`Very Unhealthy`,
        cLow: 650,
        cHigh: 1249,
      },
      hazardous: {
        description: $localize`Hazardous`,
        cLow: 1250,
        cHigh: 2049,
      },
    },
  };

  constructor() {}

  public getDescriptionFor1h(parameter: string, value: number): string {
    const currentParameter = this.breakpoints[parameter];
    for (const currentParameterKey in currentParameter) {
      if (currentParameter.hasOwnProperty(currentParameterKey)) {
        const breakpoint = currentParameter[currentParameterKey];
        if (value >= breakpoint.cLow && breakpoint.cHigh >= value) {
          return breakpoint.description;
        }
      }
    }
    return "";
  }

  public getAQIndex(readings: number[], pollutant: string): number {
    const average = this.calculateAverage(readings);
    const category = this.getCategory(average, pollutant);
    const iLow = this.breakpoints.AQI[category].iLow;
    const iHigh = this.breakpoints.AQI[category].iHigh;
    const cLow = this.breakpoints[pollutant][category].cLow;
    const cHigh = this.breakpoints[pollutant][category].cHigh;
    const index =
      Math.round(((iHigh - iLow) * (average - cLow)) / (cHigh - cLow)) + iLow;
    return index;
  }

  public getIndexDescription(index: number): string {
    const aqiCategory = this.getAQICategory(index);
    return this.breakpoints.AQI[aqiCategory].description;
  }

  public getAQIDescriptions(
    aqi: number,
    pm2_5: number = 0
  ): AqiDescriptionModel {
    const aqiCategory = this.getAQICategory(aqi);
    if (aqi === 0) {
      return {
        description: $localize`No data`,
        description_full: "",
        img: "ic-face-gray.svg",
        aqi: 0,
        pm2_5: 0,
        backgroundColor: "lightgray",
        fontColor: "gray",
      };
    }
    const general = {
      description: this.breakpoints.AQI[aqiCategory].description,
      description_full: this.breakpoints.AQI[aqiCategory].description_full,
      img: this.breakpoints.AQI[aqiCategory].img,
      aqi: aqi,
      pm2_5: pm2_5,
      backgroundColor: this.breakpoints.AQI[aqiCategory].backgroundColor,
      fontColor: this.breakpoints.AQI[aqiCategory].fontColor,
    };
    return general;
  }

  private getAQICategory(index: number) {
    let category = "";
    const currentBreakpoint = this.breakpoints.AQI;
    const good = currentBreakpoint.good;
    const moderate = currentBreakpoint.moderate;
    const unhealthyForSG = currentBreakpoint.unhealthyForSG;
    const unhealthy = currentBreakpoint.unhealthy;
    const veryUnhealthy = currentBreakpoint.veryUnhealthy;
    const hazardous = currentBreakpoint.hazardous;

    if (good.iLow <= index && index <= good.iHigh) {
      category = "good";
    } else if (moderate.iLow <= index && index <= moderate.iHigh) {
      category = "moderate";
    } else if (unhealthyForSG.iLow <= index && index <= unhealthyForSG.iHigh) {
      category = "unhealthyForSG";
    } else if (unhealthy.iLow <= index && index <= unhealthy.iHigh) {
      category = "unhealthy";
    } else if (veryUnhealthy.iLow <= index && index <= veryUnhealthy.iHigh) {
      category = "veryUnhealthy";
    } else if (hazardous.iLow <= index && index <= hazardous.iHigh) {
      category = "hazardous";
    }
    return category;
  }

  private getCategory(average: number, pollutant: string) {
    let category = "";
    const currentBreakpoint = this.breakpoints[pollutant];
    const good = currentBreakpoint.good;
    const moderate = currentBreakpoint.moderate;
    const unhealthyForSG = currentBreakpoint.unhealthyForSG;
    const unhealthy = currentBreakpoint.unhealthy;
    const veryUnhealthy = currentBreakpoint.veryUnhealthy;
    const hazardous = currentBreakpoint.hazardous;
    if (good.cLow <= average && average <= good.cHigh) {
      category = "good";
    } else if (moderate.cLow <= average && average <= moderate.cHigh) {
      category = "moderate";
    } else if (
      unhealthyForSG.cLow <= average &&
      average <= unhealthyForSG.cHigh
    ) {
      category = "unhealthyForSG";
    } else if (unhealthy.cLow <= average && average <= unhealthy.cHigh) {
      category = "unhealthy";
    } else if (
      veryUnhealthy.cLow <= average &&
      average <= veryUnhealthy.cHigh
    ) {
      category = "veryUnhealthy";
    } else if (hazardous.cLow <= average && average <= hazardous.cHigh) {
      category = "hazardous";
    }
    return category;
  }

  private calculateAverage(readings: number[]) {
    const avg = Math.round(
      readings.reduce((s, reading) => s + reading) / readings.length
    );
    return avg;
  }
}
