import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import * as Highcharts from "highcharts";
import * as ChartModuleMore from "highcharts/highcharts-more";
import HCSoldGauge from "highcharts/modules/solid-gauge";
// @ts-ignore
ChartModuleMore(Highcharts);
HCSoldGauge(Highcharts);

@Component({
  selector: "ngx-gauge",
  templateUrl: "./gauge.component.html",
  styleUrls: ["./gauge.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GaugeComponent implements OnChanges {
  @Input() public gaugeOptions: Highcharts.Options;
  @ViewChild("chart") chart: Highcharts.Chart;
  public Highcharts = Highcharts;
  public updateFlag = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty("gaugeOptions")) {
      this.updateFlag = true;
    }
  }
}
