<div class="row">
    <div class="col-sm-6 col-12">
        <canvas #rainspot width="{{length}}" height="{{length}}" class="mx-auto d-block">
            Alternative text
        </canvas>
    </div>
    <div class="col-sm-6 col-12">
        <ul class="mx-auto d-block">
            <li class="my-1">
                <div class="red-dot" style="background-color: rgb(250, 0, 0)"></div><span class="amount" i18n>Meteostation</span>
            </li>
            <li class="my-1">
                <div class="amount-box" style="background-color: rgb(217,215,215)"></div><span class="amount" i18n>No rain</span>
            </li>
            <li class="my-1">
                <div class="amount-box" style="background-color: rgb(91,238,102)"></div><span class="amount">0.02 – 0.2 mm</span>
            </li>
            <li class="my-1">
                <div class="amount-box" style="background-color: rgb(161,236,236)"></div><span class="amount">0.2 – 1.5 mm</span>
            </li>
            <li class="my-1">
                <div class="amount-box" style="background-color: rgb(128,178,227)"></div><span class="amount">1.5 – 5 mm</span>
            </li>
            <li class="my-1">
                <div class="amount-box" style="background-color: rgb(52,122,192)"></div><span class="amount">>5 mm</span>
            </li>
        </ul>
    </div>
</div>

