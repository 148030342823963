import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { Indicator } from "../../meteostations/models/indicator";
import { GlobalQuery } from "../../../store/global";

@Component({
  selector: "ngx-indicator-card",
  templateUrl: "./indicator-card.component.html",
  styleUrls: ["./indicator-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IndicatorCardComponent implements OnInit {
  @Input() indicator!: Indicator;
  public isPageShared = false;
  constructor(public globalQuery: GlobalQuery) {
    this.isPageShared = globalQuery.getValue().isShared;
  }
  ngOnInit() {}
}
