<div class="aqi-info-container row current-state m-0 py-3 d-flex justify-content-around"
     [ngStyle]="{'background-color': this.descriptions.backgroundColor}"
>
    <div class="image-box col-lg-2 col-sm-4 col-12" style="width: 130px; height: 130px">
        <img src="../../../../../../assets/aqi-descriptions/{{this.descriptions.img}}" alt=""
             style="width: 120px; height: 120px">
    </div>
    <div class="parameters col-lg-2 col-sm-4 col-12" [ngStyle]="{'background-color': this.descriptions.fontColor}">
        <div class="d-flex justify-content-between flex-column align-items-start h-100">
            <div class="aqi">AQI</div>
            <div class="aqi-value">{{this.descriptions.aqi}}</div>
            <div class="aqi mt-3">PM 2.5</div>
            <div class="aqi-value"><span>{{this.descriptions.pm2_5}} <span style="font-size: 12pt">μg/m3</span></span></div>
            <div class="aqi pm2_5_value-description" i18n>{{this.pm2_5_over_standard}} times more than the norm</div>
        </div>
    </div>
    <div class="description col-lg-6 col-sm-12 col-12 order-lg-2 order-sm-3"
         [ngStyle]="{'color': this.descriptions.fontColor}">
        <div class="h-100 ps-3 d-flex flex-column justify-content-center">
            <h4 class="mb-2 mt-2 text-center"
                [ngStyle]="{'color': this.descriptions.fontColor}">{{this.descriptions.description}}</h4>
            <p class="text-center" [ngStyle]="{'color': this.descriptions.fontColor}">
                {{this.descriptions.description_full}}
            </p>
        </div>
    </div>
    <div class="location col-lg-2 col-sm-4 col-12 order-lg-3 order-sm-2" *ngIf="isShared && !isComparison">
        <div class="d-flex flex-column justify-content-center h-100">
            <div class="text-center">
                <h5 [ngStyle]="{'color': this.descriptions.fontColor}"><nb-icon icon="pin-outline"></nb-icon>{{this.descriptions.station.name}}</h5>
                <p [ngStyle]="{'color': this.descriptions.fontColor}">
                    <a role="button" (click)="showMap()" i18n>Select another station</a>
                </p>
            </div>
        </div>
    </div>
</div>
