import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {LOCALE_ID, NgModule} from "@angular/core";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {CoreModule} from "./@core/core.module";
import {AppComponent} from "./app.component";
import {AppRoutingModule} from "./app-routing.module";
import {ThemeModule} from "./@theme/theme.module";
import {AuthModule} from "./@auth/auth.module";

import {
    NbDatepickerModule,
    NbDialogModule,
    NbMenuModule,
    NbSidebarModule,
    NbToastrModule,
    NbWindowModule,
} from "@nebular/theme";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {LoadingInterceptor} from "./@core/interceptors/loading.interceptor";
import {SharedModule} from "./shared/shared.module";
import {MatSelectModule} from "@angular/material/select";

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,

        AuthModule.forRoot(),
        NbSidebarModule.forRoot(),
        NbMenuModule.forRoot(),
        NbDatepickerModule.forRoot(),
        NbDialogModule.forRoot(),
        NbWindowModule.forRoot(),
        NbToastrModule.forRoot(),
        // NbChatModule.forRoot({
        //   messageGoogleMapKey: "AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY",
        // }),
        CoreModule.forRoot(),
        ThemeModule.forRoot(),
        MatProgressBarModule,
        MatSelectModule,
        SharedModule,
    ],
    bootstrap: [AppComponent],
    providers: [{provide: LOCALE_ID, useValue: "uz"}, {
        provide: HTTP_INTERCEPTORS,
        useClass: LoadingInterceptor,
        multi: true
    },],
    exports: [],
})
export class AppModule {
}
