<p-toast position="top-right" key="excel-file"></p-toast>
<p-panel class="data-table" styleClass="mb-2">
    <ng-template pTemplate="header">
        <div class="w-100 d-flex justify-content-between align-items-center">
            <label class="d-inline-block" i18n>{{title}}</label>

            <div *ngIf="statisticData.length" class="d-flex align-items-center">
                <div class="form-group align-items-center">
                    <input type="radio"
                           [(ngModel)]="selectedTimeRange"
                           (change)="regenerateTableData()"
                           class="form-check-input"
                           value="hourly"
                           id="hourly"
                           name="time-interval">
                    <label for="hourly" class="form-check-label mx-1" i18n>Hourly</label>
                </div>

                <div class="form-group mx-2 align-items-center">
                    <input type="radio"
                           [(ngModel)]="selectedTimeRange"
                           (change)="regenerateTableData()"
                           class="form-check-input"
                           value="daily"
                           id="daily"
                           name="time-interval">
                    <label for="daily" class="form-check-label mx-1" i18n>Daily</label>
                </div>

                <button class="d-inline-block btn btn-success excel-button py-1 px-2"
                        (click)="exportToExcel(table)">Excel
                    <nb-icon icon="download"></nb-icon>
                </button>
            </div>
        </div>
    </ng-template>
    <div class="panel-body p-0">
        <div *ngIf="!(globalQuery.select('loading') | async) else loading">
            <div class="wrapper">
                <div *ngIf="statisticData.length; else noMeteostationData">
                    <cdk-virtual-scroll-viewport itemSize="0" class="example-viewport" style="height: 400px">
                        <table class="table statistic-table table-striped mb-0" #table>
                            <thead>
                                <tr class="sticky-top bg-white">
                                    <th *ngFor="let meta of statisticMeta"
                                        [ngClass]="{'time-cell': meta.field === timeKey}">{{meta.caption}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *cdkVirtualFor="let item of statisticData">
                                    <tr>
                                        <td *ngFor="let meta of statisticMeta" class="text-center"
                                            [ngClass]="{'time-cell': meta.field === timeKey}">
                                            <div *ngIf="item[meta.field] || item[meta.field] === 0; else noData">
                                                <div [ngSwitch]="meta.field">
                                                    <span *ngSwitchCase="timeKey">
                                                        {{item[meta.field] | date : 'YYYY-MM-dd HH:mm' : timezone : 'en'}}
                                                    </span>
                                                    <ngx-wind-direction *ngSwitchCase="windDirection"
                                                                        [degree]="item[meta.field]"></ngx-wind-direction>
                                                    <ngx-battery *ngSwitchCase="'Batt'"
                                                                 [battery]="item[meta.field]">
                                                    </ngx-battery>
                                                    <span *ngSwitchCase="'pictocode'">
                                                        <img
                                                                src="../../../../assets/images/pictocode/{{item[meta.field] | pictocode: item.isdaylight}}"
                                                                alt=""
                                                                class="pictocode-image"
                                                                (click)="openDialog(item)">
                                                    </span>
                                                    <span *ngSwitchDefault>
                                                        {{item[meta.field] | round: meta.fraction}} {{meta.suffix}}
                                                    </span>
                                                </div>
                                            </div>
                                            <ng-template #noData>-</ng-template>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </cdk-virtual-scroll-viewport>
                </div>
                <ng-template #noMeteostationData>
                    <div class="p-2">
                        <p-message styleClass="message w-100" severity="warn" i18n-text
                                   text="No data"></p-message>
                    </div>
                </ng-template>
            </div>
        </div>
        <ng-template #loading>
            <div class="wrapper">
                <table class="table statistic-table table-striped mb-0">
                    <thead>
                    <tr>
                        <th *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9]">
                            <p-skeleton styleClass="m-1"></p-skeleton>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9]">
                        <td *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9]">
                            <p-skeleton styleClass="m-1 mx-auto" width="50%"></p-skeleton>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </ng-template>
    </div>
</p-panel>