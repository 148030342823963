import {
  Component,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { CityMapComponent } from "../../../public/public-meteostations/shared-dashboard/city-map/city-map.component";
import { NbDialogService } from "@nebular/theme";
import { GlobalStore } from "../../../store/global";

@Component({
  selector: "ngx-aqi-info",
  templateUrl: "./aqi-info.component.html",
  styleUrls: ["./aqi-info.component.scss"],
})
export class AqiInfoComponent implements OnInit, OnChanges {
  @Input() descriptions;
  public pm2_5_over_standard;
  public isShared = false;
  public isComparison = false;

  constructor(
    private zone: NgZone,
    private dialogService: NbDialogService,
    private globalStore: GlobalStore
  ) {
    this.isShared = globalStore.getValue().isShared;
    this.isComparison = globalStore.getValue().isComparison;
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    this.descriptions.pm2_5 = Math.round(this.descriptions.pm2_5);
    this.pm2_5_over_standard = this.descriptions.pm2_5
      ? Math.round(this.descriptions.pm2_5 / 5)
      : 0;
  }

  public showMap() {
    this.zone.run(() => {
      this.dialogService.open(CityMapComponent, {
        closeOnBackdropClick: true,
        hasScroll: false,
        dialogClass: "mx-auto",
      });
    });
  }
}
