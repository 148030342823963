import {ChangeDetectionStrategy, Component, OnInit} from "@angular/core";
import {AnalyticsService} from "./@core/utils";
import {GlobalLoaderService} from "./@theme/services/global-loader.service";
import {ActivatedRoute, Router} from "@angular/router";
import {GlobalStore} from "./store/global";
import {NbIconLibraries} from "@nebular/theme";
import {HighchartsConfigService} from "./@core/services/highcharts-config.service";
import * as Highcharts from "highcharts";
import * as HighchartsStock from "highcharts/highstock";
import Exporting from "highcharts/modules/exporting";
import BoostModule from "highcharts/modules/boost";
import {LoadingService} from "./@core/services/loading.service";
import {debounceTime} from "rxjs/operators";

BoostModule(Highcharts);
BoostModule(HighchartsStock);
Exporting(Highcharts);
Exporting(HighchartsStock);

@Component({
    selector: "ngx-app",
    templateUrl: "./app.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
    public loading = false;
    isLoading$ = this.loadingService.isLoading$.pipe(debounceTime(200))

    constructor(
        //   loaderService is used in template don't remove
        public loaderService: GlobalLoaderService,
        private analytics: AnalyticsService,
        private globalStore: GlobalStore,
        private route: ActivatedRoute,
        private router: Router,
        private nbIconLibraries: NbIconLibraries,
        private highchartsConfigService: HighchartsConfigService,
    private loadingService: LoadingService,
    ) {
        this.nbIconLibraries.registerFontPack("fas", {
            packClass: "fas",
            iconClassPrefix: "fa",
        });
        this.nbIconLibraries.registerFontPack("far", {
            packClass: "far",
            iconClassPrefix: "fa",
        });
        this.nbIconLibraries.registerFontPack("fab", {
            packClass: "fab",
            iconClassPrefix: "fa",
        });
        this.nbIconLibraries.setDefaultPack("fas");
        this.route.queryParams.subscribe((queryParams) => {
            const isShared = +queryParams["shared"] === 1;
            const isComparison = router.url.includes("comparison");
            globalStore.update({isShared, isComparison});
        });
    }

    ngOnInit(): void {
        this.analytics.trackPageViews();
    }
}