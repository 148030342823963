import { Injectable } from "@angular/core";
import { HttpService } from "./common/api/http.service";
import { User } from "../../pages/accounts/users/dto/user";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CurrentUserService {
  public user: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  constructor(private http: HttpService) {}

  public async getUser(): Promise<User> {
    const resp: User = await this.http.get(`users/current`).toPromise();
    this.user.next(resp);
    return resp;
  }
}
