<p-panel  [ngClass]="{shared: isPageShared}" styleClass="my-2 shared">
    <ng-template pTemplate="header" class="p-3">
        <h6 class="mb-0">{{indicator.title}}</h6>
    </ng-template>
    <div class="panel-body">
        <div class="container-fluid p-0 mx-auto">
            <div class="row mb-3">
                <div class="col-3 px-1">
                    <img src="../../../../../assets/icons/current-readings-icons/{{indicator.imgSrc}}" class="icon d-block mx-auto {{indicator.class}}" alt="a">
                </div>
                <div class="col-9 px-1">
                    <h6 class="primary-header text-dark">{{indicator.latest | round: indicator.fraction}} <span class="primary-suffix">{{indicator.suffix}}</span></h6>
                    <hr>
                    <div class="extra">
                        <p *ngFor="let extraIndicator of indicator.extraIndicators" [ngSwitch]="extraIndicator.field">
<!--                            <b class="extra-indicator-value" *ngIf="extraIndicator.field !== 'WindD'; else windDirection">-->
<!--                                {{extraIndicator.latest | round: extraIndicator.fraction}} {{extraIndicator.suffix}}-->
<!--                            </b>-->
                            <ng-container *ngSwitchDefault>
                                <b class="extra-indicator-value">
                                    {{extraIndicator.latest | round: extraIndicator.fraction}} {{extraIndicator.suffix}}
                                </b>
                                <span> ({{extraIndicator.title}})</span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'WindD'">
                                <ngx-wind-direction [degree]="extraIndicator.latest"></ngx-wind-direction>
                                <span> ({{extraIndicator.title}})</span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'uvindex'">
                                <b class="extra-indicator-value">
                                    {{extraIndicator.latest | round: extraIndicator.fraction}} {{extraIndicator.suffix}}
                                </b>
                                <span> ({{extraIndicator.title}})</span>
                                <span class="additional-info px-2">{{extraIndicator.additional}}</span>
                            </ng-container>
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4 px-1">
                    <p class="secondary-header mb-1 text-dark">{{indicator.max | round: indicator.fraction}} <span class="secondary-suffix">{{indicator.suffix}}</span></p>
                    <p class="secondary-subheader text-secondary" i18n>Max</p>
                </div>
                <div class="col-4 px-1">
                    <p class="secondary-header mb-1 text-dark">{{indicator.average | round: indicator.fraction}} <span class="secondary-suffix">{{indicator.suffix}}</span></p>
                    <p class="secondary-subheader text-secondary" i18n>Avg</p>
                </div>
                <div class="col-4 px-1">
                    <p class="secondary-header mb-1 text-dark">{{indicator.min | round: indicator.fraction}} <span class="secondary-suffix">{{indicator.suffix}}</span></p>
                    <p class="secondary-subheader text-secondary" i18n>Min</p>
                </div>
            </div>
        </div>
    </div>
</p-panel>
<ng-template #skeleton>
    <nb-card class="my-2">
        <nb-card-header class="p-3 bg-light">
            <p-skeleton width="50%" height="1.5rem"></p-skeleton>
        </nb-card-header>
        <nb-card-body class="p-3">
            <div class="container-fluid p-0 mx-auto">
                <div class="row mb-3">
                    <div class="col-3 px-1">
                        <p-skeleton width="4rem" height="4rem"></p-skeleton>
                    </div>
                    <div class="col-9 px-1">
                        <p-skeleton styleClass="mx-auto" width="50%" height="1.75rem"></p-skeleton>
                        <hr>
                        <div class="extra">
                            <p>
                                <p-skeleton styleClass="mx-auto" width="75%" height="1rem"></p-skeleton>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 px-1">
                        <p-skeleton styleClass="mx-auto mb-2" width="50%" height="1.25rem"></p-skeleton>
                        <p-skeleton styleClass="mx-auto" width="60%" height="1rem"></p-skeleton>
                    </div>
                    <div class="col-4 px-1">
                        <p-skeleton styleClass="mx-auto mb-2" width="50%" height="1.25rem"></p-skeleton>
                        <p-skeleton styleClass="mx-auto" width="60%" height="1rem"></p-skeleton>
                    </div>
                    <div class="col-4 px-1">
                        <p-skeleton styleClass="mx-auto mb-2" width="50%" height="1.25rem"></p-skeleton>
                        <p-skeleton styleClass="mx-auto" width="60%" height="1rem"></p-skeleton>
                    </div>
                </div>
            </div>
        </nb-card-body>
    </nb-card>
</ng-template>
