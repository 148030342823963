import {Component, Input, OnInit} from "@angular/core";
import {NbDialogRef} from "@nebular/theme";

@Component({
  selector: "ngx-modal",
  template: `
    <nb-card>
      <nb-card-header>{{ title }}</nb-card-header>
      <nb-card-body>
        <p>{{ question }}</p>
      </nb-card-body>
      <nb-card-footer class="d-flex justify-content-between">
        <div>
          <button
            class="cancel"
            matRipple
            nbButton
            status="danger"
            (click)="submit(false)"
          >
            No
          </button>
        </div>
        <div>
          <button nbButton matRipple status="success" (click)="submit(true)">
            Yes
          </button>
        </div>
      </nb-card-footer>
    </nb-card>
  `,
  styleUrls: ["./modal.component.scss"],
})
export class ModalComponent implements OnInit {
  constructor(protected ref: NbDialogRef<ModalComponent>) {}

  @Input() title: string;
  @Input() question: string;

  ngOnInit(): void {}

  submit(agree: boolean): void {
    this.ref.close(agree);
  }
}
``