import {NgModule} from "@angular/core";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HighchartsChartModule} from "highcharts-angular";
import {LeafletMarkerClusterModule} from "@asymmetrik/ngx-leaflet-markercluster";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {CdkTableModule} from "@angular/cdk/table";

// Components
import {BatteryComponent} from "./components/battery/battery.component";
import {WindDirectionComponent} from "./components/wind-direction/wind-direction.component";
import {ModalComponent} from "./components/modal/modal.component";
import {IndicatorCardComponent} from "./components/indicator-card/indicator-card.component";
import {GaugeComponent} from "./meteostations/components/gauge/gauge.component";
import {ChartComponent} from "./meteostations/components/chart/chart.component";
import {AqiInfoComponent} from "./components/aqi-info/aqi-info.component";
import {StatisticTableComponent} from "./components/statistic-table/statistic-table.component";
import {RainspotComponent} from "./components/rainspot/rainspot.component";
import {MapWidgetComponent} from "./meteostations/components/map-widget/map-widget.component";

// Pipes
import {RoundPipe} from "./pipes/round.pipe";
import {SafePipe} from "./pipes/safe.pipe";
import {PictocodePipe} from "./pipes/pictocode.pipe";

// Nebular modules
import {
    NbActionsModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbDialogModule,
    NbIconModule,
    NbSelectModule,
    NbSpinnerModule,
} from "@nebular/theme";

// PrimeNG
import {AccordionModule} from "primeng/accordion";
import {PanelModule} from "primeng/panel";
import {SkeletonModule} from "primeng/skeleton";
import {DropdownModule} from "primeng/dropdown";
import {ToastModule} from "primeng/toast";
import {MessageModule} from "primeng/message";
import {InputNumberModule} from "primeng/inputnumber";
import {ConfirmationService, MessageService} from "primeng/api";
import {RadioButtonModule} from "primeng/radiobutton";
import {CustomProgressBarComponent} from "./components/custom-progress-bar/custom-progress-bar.component";
import {JsonEditorComponent} from "./components/json-editor/json-editor.component";

const components = [
  BatteryComponent,
  WindDirectionComponent,
  ModalComponent,
  IndicatorCardComponent,
  GaugeComponent,
  ChartComponent,
  AqiInfoComponent,
  StatisticTableComponent,
  RainspotComponent,
  MapWidgetComponent,
];
const nebularModules = [
  NbIconModule,
  NbSpinnerModule,
  NbCardModule,
  NbCheckboxModule,
  NbActionsModule,
  NbDialogModule,
  NbSelectModule,
  NbButtonModule,
];
const primeNgModules = [
  AccordionModule,
  PanelModule,
  SkeletonModule,
  DropdownModule,
  ToastModule,
  MessageModule,
  InputNumberModule,
  RadioButtonModule,
];

@NgModule({
  declarations: [
    // Components
    ...components,

    // Pipes
    RoundPipe,
    SafePipe,
    PictocodePipe,
    CustomProgressBarComponent,
      JsonEditorComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    HighchartsChartModule,
    LeafletMarkerClusterModule,
    FontAwesomeModule,
    // Nebular modules
    ...nebularModules,

    // PrimeNG modules
    ...primeNgModules,
    CdkTableModule,
    NgOptimizedImage,
    RadioButtonModule,
  ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HighchartsChartModule,
        LeafletMarkerClusterModule,
        FontAwesomeModule,

        // Components
        ...components,

        // Pipes
        PictocodePipe,
        RoundPipe,
        SafePipe,

        // Nebular modules
        ...nebularModules,

        // PrimeNG modules
        ...primeNgModules,
        CustomProgressBarComponent,
        JsonEditorComponent,
    ],
  providers: [MessageService, ConfirmationService],

})
export class SharedModule {}
