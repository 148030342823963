import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "pictocode",
})
export class PictocodePipe implements PipeTransform {
  transform(value: number, isdaylight: number): string {
    const code = value < 10 ? "0" + value : value;
    const src = code + "_" + (isdaylight ? "day" : "night") + ".svg";
    return src;
  }
}
