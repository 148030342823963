import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { DynamicDialogConfig } from "primeng/dynamicdialog";

@Component({
  selector: "ngx-rainspot",
  templateUrl: "./rainspot.component.html",
  styleUrls: ["./rainspot.component.scss"],
})
export class RainspotComponent implements OnInit, AfterViewInit {
  @ViewChild("rainspot") rainspotElement: ElementRef;
  @Input("item") item: any;
  public visible;
  public length = 220;
  public spotLength = 150;
  public gridLength = this.spotLength / 7;
  private rulerHeight = 20;
  private rulerWidth = this.spotLength;
  private border = (this.length - this.spotLength) / 2;
  private colors = {
    norain: "rgb(217,215,215)",
    lightrain: "rgb(161,236,236)",
    medium: "rgb(128,178,227)",
    heavy: "rgb(52,122,192)",
    shower: "rgb(91,238,102)",
    "0": "rgb(217,215,215)",
    "1": "rgb(161,236,236)",
    "2": "rgb(128,178,227)",
    "3": "rgb(52,122,192)",
    "9": "rgb(91,238,102)",
  };
  constructor(public config: DynamicDialogConfig) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.drawRainspot();
  }

  private drawRainspot() {
    this.item = this.config.data.item;
    const canvas = this.rainspotElement.nativeElement as HTMLCanvasElement;
    const ctx = canvas.getContext("2d");
    if (canvas.getContext) {
      ctx.save();
      this.drawGrid(ctx);
      this.drawCircles(ctx);
      this.drawDirections(ctx);
      this.drawRuler(ctx);
    }
  }

  private drawGrid(ctx: CanvasRenderingContext2D) {
    let gridRows = [];
    for (let i = 0; i < this.item.rainspot.length; i += 7) {
      gridRows.unshift(this.item.rainspot.slice(i, i + 7));
    }
    for (let y = 0; y < 7; y++) {
      for (let x = 0; x < 7; x++) {
        const colorCode = gridRows[y][x];
        ctx.fillStyle = this.colors[colorCode];
        ctx.fillRect(
          x * this.gridLength + this.border,
          y * this.gridLength + this.border + this.rulerHeight / 2,
          this.gridLength,
          this.gridLength
        );
      }
    }
  }

  private drawCircles(ctx: CanvasRenderingContext2D) {
    for (let i = 1; i <= 4; i++) {
      ctx.beginPath();
      ctx.arc(
        this.gridLength * 3.5 + this.border,
        this.gridLength * 3.5 + this.border + this.rulerHeight / 2,
        (this.gridLength * (2 * i - 1)) / 2,
        0,
        Math.PI * 2,
        false
      );
      ctx.strokeStyle = "rgb(0, 0, 0)";
      ctx.stroke();
    }
    ctx.beginPath();
    ctx.arc(
      this.length / 2,
      this.length / 2 + this.rulerHeight / 2,
      2,
      0,
      Math.PI * 2,
      false
    );
    ctx.fillStyle = "rgb(250, 0, 0)";
    ctx.fill();
  }

  private drawDirections(ctx: CanvasRenderingContext2D) {
    ctx.font = "16px serif";
    ctx.fillStyle = "black";
    ctx.fillText($localize`North`, this.spotLength / 2, this.border / 2);
    ctx.translate(this.border / 2, this.length / 2 + this.rulerHeight / 2 + 20);
    ctx.rotate((-90 * Math.PI) / 180);
    ctx.fillText($localize`West`, 0, 0);
    ctx.restore();
    ctx.font = "16px serif";
    ctx.fillStyle = "black";
    ctx.fillText(
      $localize`South`,
      this.spotLength / 2,
      this.spotLength + (3 * this.border) / 2 + this.rulerHeight / 2
    );
    ctx.translate(
      this.length - this.border / 2 - 5,
      this.length / 2 - 25 + this.rulerHeight / 2
    );
    ctx.rotate((90 * Math.PI) / 180);
    ctx.fillText($localize`East`, 0, 0);
    ctx.restore();
  }

  private drawRuler(ctx: CanvasRenderingContext2D) {
    ctx.restore();
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.save();
    ctx.beginPath();
    ctx.lineWidth = 2;
    ctx.strokeStyle = "rgb(0,0,0)";
    const lineStart = {
      x: this.border,
      y: this.border + this.rulerHeight - 12.5,
    };
    const lineEnd = {
      x: this.spotLength + this.border,
      y: this.border + this.rulerHeight - 12.5,
    };
    ctx.moveTo(this.border, this.border + this.rulerHeight - 12.5);
    ctx.lineTo(
      this.spotLength + this.border,
      this.border + this.rulerHeight - 12.5
    );
    ctx.stroke();

    ctx.font = "12px sans-serif";
    ctx.moveTo(lineStart.x, lineStart.y);
    ctx.lineTo(lineStart.x, lineStart.y - 5);
    ctx.fillText("-6km", lineStart.x - 10, lineStart.y - 10);
    ctx.stroke();

    ctx.moveTo((lineStart.x + lineEnd.x) / 2, lineStart.y);
    ctx.lineTo((lineStart.x + lineEnd.x) / 2, lineStart.y - 5);
    ctx.fillText("0", (lineStart.x + lineEnd.x) / 2 - 5, lineStart.y - 10);
    ctx.stroke();

    ctx.moveTo(lineEnd.x, lineEnd.y);
    ctx.lineTo(lineEnd.x, lineEnd.y - 5);
    ctx.fillText("6km", lineEnd.x - 10, lineEnd.y - 10);
    ctx.stroke();
  }
}
