import {Component, EventEmitter, OnDestroy, OnInit, Output,} from "@angular/core";
import {NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService,} from "@nebular/theme";
import {LayoutService, RippleService} from "../../../@core/utils";
import {filter, map, takeUntil} from "rxjs/operators";
import {Observable, Subject} from "rxjs";
import {UserStore} from "../../../@core/stores/user.store";
import {SettingsData} from "../../../@core/interfaces/common/settings";
import {User} from "../../../@core/interfaces/common/users";
import {MeteostationsQuery} from "../../../store/meteostations/state";
import {environment} from "../../../../environments/environment";

@Component({
    selector: "ngx-header",
    styleUrls: ["./header.component.scss"],
    templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
    @Output() public rightMenuClicked: EventEmitter<void> =
        new EventEmitter<void>();

    public readonly materialTheme$: Observable<boolean>;
    public isNewUU = false;
    private destroy$: Subject<void> = new Subject<void>();
    userPictureOnly: boolean = false;
    user: User;

    themes = [
        {
            value: "material-light",
            name: "Material Light",
        },
        {
            value: "material-dark",
            name: "Material Dark",
        },
    ];
    languages = [
        {
            value: "en-US",
            title: "English",
        },
        {
            value: "ru",
            title: "Русский",
        },
        {
            value: "uz",
            title: "O'zbek",
        }
    ];

    currentTheme = "material-light";
    userMenu = this.getMenuItems();

    constructor(
        private sidebarService: NbSidebarService,
        private menuService: NbMenuService,
        private themeService: NbThemeService,
        private userStore: UserStore,
        private settingsService: SettingsData,
        private layoutService: LayoutService,
        private breakpointService: NbMediaBreakpointsService,
        private rippleService: RippleService,
        private meteostationsQuery: MeteostationsQuery,
        private nbMenuService: NbMenuService,
    ) {
        this.materialTheme$ = this.themeService.onThemeChange().pipe(
            takeUntil(this.destroy$),
            map((theme) => {
                const themeName: string = (theme && theme.name) || "";
                return themeName.startsWith("material");
            })
        );
    }

    getMenuItems() {
        return [
            {title: $localize`Log out`, link: "/auth/logout"},
        ];
    }

    ngOnInit() {
        this.currentTheme = this.themeService.currentTheme;
        this.userStore
            .onUserStateChange()
            .pipe(takeUntil(this.destroy$))
            .subscribe((user: User) => {
                this.user = user;
                this.userMenu = this.getMenuItems();
            });

        this.meteostationsQuery.select('currentMeteostation')
            .pipe(takeUntil(this.destroy$))
            .subscribe(station => {
                if (station) {
                    const currentOrg = station.org_id;
                    // change header title for a while, mr. president is coming to newuu.uz
                    this.isNewUU = currentOrg == 17;
                }
            });

        this.nbMenuService.onItemClick()
            .pipe(
                takeUntil(this.destroy$),
                filter(({tag}) => tag === 'language-switcher')
            ).subscribe(async (event) => {
                const language = event.item['value'];
                await this.changeLanguage(language);
            });

        const {xl} = this.breakpointService.getBreakpointsMap();
        this.themeService
            .onMediaQueryChange()
            .pipe(
                map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
                takeUntil(this.destroy$)
            )
            .subscribe(
                (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
            );

        this.themeService
            .onThemeChange()
            .pipe(
                map(({name}) => name),
                takeUntil(this.destroy$)
            )
            .subscribe((themeName) => {
                this.currentTheme = themeName;
                this.rippleService.toggle(themeName.startsWith("material"));
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    changeTheme(themeName: string) {
        this.userStore.setSetting(themeName);
        this.settingsService
            .updateCurrent(this.userStore.getUser().settings)
            .pipe(takeUntil(this.destroy$))
            .subscribe();

        this.themeService.changeTheme(themeName);
    }

    async changeLanguage(language: string) {
        window.open(environment.baseUrl + '/' + language, '_self');
        // await this.router.navigate([environment.baseUrl + '/' + language]);
    }

    toggleSidebar(): boolean {
        this.sidebarService.toggle(true, "menu-sidebar");
        this.layoutService.changeLayoutSize();
        return false;
    }

    navigateHome() {
        this.menuService.navigateHome();
        return false;
    }
}
