import {
  NgZone,
  OnInit,
  OnDestroy,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  LOCALE_ID,
  Inject,
  Component,
  ViewChild,
} from "@angular/core";
import { Device } from "../../../../@core/interfaces/common/device";
import * as L from "leaflet";
import { LatLng, LatLngBounds, LatLngTuple } from "leaflet";
import { Subscription } from "rxjs";
import {
  MeteostationsQuery,
  MeteostationsService,
  MeteostationsStore,
} from "../../../../store/meteostations/state";
import {
  MeteostationParameters,
  Parameter,
  parameters,
} from "../../meteostation-parameters";
import { HelperService } from "../../../../@core/utils/helper.service";
import { kriging } from "./kriging";
import { SpecialIndicatorsService } from "../../services/special-indicators.service";
import { HexToRGBService } from "../../../../@core/utils/hex-to-rgb.service";

@Component({
  selector: "ngx-map-widget",
  templateUrl: "./map-widget.component.html",
  styleUrls: ["./map-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapWidgetComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("mapMeteoWidget", { static: false }) mapWidget: ElementRef;
  public markerClusterGroup: L.MarkerClusterGroup;
  public mapOpened = true;
  public parametersArr: Parameter[];
  public parameters: MeteostationParameters = { ...parameters };
  public selectedParameter = "AirT";
  private parameterValues;
  private subscription$: Subscription;
  private parameterValues$: Subscription;
  private map: L.Map = null;
  private heatmapLayers: L.Rectangle[] = [];
  private devices: Device[] = [];
  private bounds: LatLngBounds;
  private currentDevice: Device;
  private zoom = 15;
  private initialCoords: LatLngTuple = [41.3710955885187, 69.206900484656];
  private words = {
    deviceInfo: $localize`Device Info`,
    deviceName: $localize`Device Name`,
    deviceSerial: $localize`Device Serial`,
    address: $localize`Address`,
    lastSignal: $localize`Last Signal`,
    battery: $localize`Battery`,
    volt: $localize`Volt`,
  };
  public greenDvcGroup: L.LayerGroup;
  public redDvcGroup: L.LayerGroup;
  public yellowDvcGroup: L.LayerGroup;

  public constructor(
    @Inject(LOCALE_ID) public locale: string,
    public meteostationsQuery: MeteostationsQuery,
    private meteostationsStore: MeteostationsStore,
    private meteostationsService: MeteostationsService,
    private helperService: HelperService,
    private specialIndicatorsService: SpecialIndicatorsService,
    private chd: ChangeDetectorRef,
    private zone: NgZone,
    private hexToRGBService: HexToRGBService
  ) {}

  public ngOnInit(): void {
    this.subscription$ = this.meteostationsQuery
      .select(["parameters", "currentMeteostation"])
      .subscribe((data) => {
        const { parameters, currentMeteostation } = data;
        const device = currentMeteostation;
        if (device) {
          this.currentDevice = device;
          this.devices = this.meteostationsQuery.getValue().meteostations;
        }
        if (device && parameters && Object.keys(parameters).length !== 0) {
          if (this.map) {
            this.map.eachLayer((layer) => this.map.removeLayer(layer));
            this.map.setView(
              new LatLng(
                device.geolocation.coordinates[0],
                device.geolocation.coordinates[1]
              ),
              this.zoom
            );
          }
          this.parameters = { ...parameters };
          this.parametersArr = Object.values(parameters).filter(
            (p) => !p.isForecast
          );
          this.getParameterValues();
          this.chd.detectChanges();
        }
      });
  }

  public ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      this.initMap();      
      this.chd.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this.devices = [];
    this.subscription$?.unsubscribe();
    this.parameterValues$?.unsubscribe();
  }

  public getParameterValues() {
    if (this.currentDevice) {
      this.selectedParameter = "AirT";
      if (this.currentDevice.org_id === 10) {
        this.selectedParameter = "PM2.5";
      }
      
      if (this.currentDevice.serial_number === "114072024") {
        this.selectedParameter = "AlphaPM2_5";
      }
      this.parameterValues$ = this.meteostationsService
        .getMapDeviceValueByParameter(this.selectedParameter)
        .subscribe((d) => {
          this.parameterValues = Object.values(d.data);
          if (this.parameters.hasOwnProperty(this.selectedParameter)) {
            if (this.parameters[this.selectedParameter].isSpecialReading) {
              if (this.parameterValues.length > 1) {
                this.generateHeatmapValues(this.parameterValues);
              }
            }
            this.zone.runOutsideAngular(() => {
              this.refreshMap();
            });
            this.chd.detectChanges();
          }
        });
    }
  }

  public onParameterChange(parameter: string) {
    this.parameterValues$?.unsubscribe();
    this.clearHeatmap();
    this.parameterValues$ = this.meteostationsService
      .getMapDeviceValueByParameter(parameter)
      .subscribe((d) => {
        this.parameterValues = Object.values(d.data);
        if (this.parameters.hasOwnProperty(this.selectedParameter)) {
          if (this.parameters[parameter].isSpecialReading) {
            if (this.parameterValues.length > 1) {
              this.generateHeatmapValues(this.parameterValues);
            }
          }
          this.markerClusterGroup.eachLayer((l) => {
            const tooltip = l.getTooltip();
            const serialNumber = tooltip.options.className;
            tooltip.setContent(
              "<span>" + this.getMeasurementValue(serialNumber) + "</span>"
            );
          });
        }
      });
  }

  public refreshView(): void {
    this.map.fitBounds(this.bounds);
    this.map.invalidateSize();
  }

  public toggleGroup(group: L.LayerGroup, button?: HTMLButtonElement) {
    group.eachLayer((layer) => {
      if (this.markerClusterGroup.hasLayer(layer)) {
        this.markerClusterGroup.removeLayer(layer);
        button.setAttribute("style", "opacity: 0.6");
      } else {
        this.markerClusterGroup.addLayer(layer);
        button.removeAttribute("style");
      }
    });
  }

  public resetMap() {
    this.refreshView();
    this.mapOpened = true;
  }

  private initMap(): void {
    this.map = L.map(this.mapWidget.nativeElement, {
      zoom: this.zoom,
      maxZoom: 18,
      markerZoomAnimation: false,
      scrollWheelZoom: true,
      preferCanvas: true,
      renderer: new L.Canvas(),
    }).setView(this.initialCoords, this.zoom);
    if (this.currentDevice) {
      this.map.setView(
        new LatLng(
          this.currentDevice.geolocation.coordinates[0],
          this.currentDevice.geolocation.coordinates[1]
        ),
        this.zoom
      );
    }
  }

  private setTileLayer() {
    const tiles = L.tileLayer(
      "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      {
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        minZoom: 5,
        maxZoom: 18,
      }
    );
    tiles.addTo(this.map);
  }

  private refreshMap(): void {
    this.setTileLayer();
    const latLong = [];
    const greenDevices = []; //  green means device is working
    const redDevices = []; // red means device is not working
    const yellowDevices = []; // yellow means device is possibly not working
    const greenStation = "./assets/images/map/meteostation_green.png";
    const yellowStation = "./assets/images/map/meteostation_yellow.png";
    const redStation = "./assets/images/map/meteostation_red.png";
    for (const device of this.devices) {
      const diffTime =
        (new Date().getTime() - new Date(device.last_signal).getTime()) /
        (1000 * 60 * 60);
      let marker;
      if (diffTime < 6) {
        marker = this.generateMarker(device, greenStation);
        greenDevices.push(marker);
      } else if (diffTime < 12) {
        marker = this.generateMarker(device, yellowStation);
        yellowDevices.push(marker);
      } else {
        marker = this.generateMarker(device, redStation);
        redDevices.push(marker);
      }
      latLong.push(
        new LatLng(
          device.geolocation.coordinates[0],
          device.geolocation.coordinates[1]
        )
      );
    }
    this.greenDvcGroup = L.layerGroup(greenDevices);
    this.redDvcGroup = L.layerGroup(redDevices);
    this.yellowDvcGroup = L.layerGroup(yellowDevices);
    this.markerClusterGroup = L.markerClusterGroup({
      removeOutsideVisibleBounds: true,
      showCoverageOnHover: false,
      maxClusterRadius: 60,
      iconCreateFunction: function (cluster) {
        return L.divIcon({
          html:
            "<div class='marker-cluster-default'><div><span>" +
            cluster.getChildCount() +
            "</span></div></div>",
          className: "marker-cluster",
        });
      },
    })
      .addLayers([
        ...this.greenDvcGroup.getLayers(),
        ...this.yellowDvcGroup.getLayers(),
        ...this.redDvcGroup.getLayers(),
      ])
      .addTo(this.map);
    this.bounds = new L.LatLngBounds(latLong);
  }

  private generateMarker(device, image) {
    return L.marker(
      new LatLng(
        device.geolocation.coordinates[0],
        device.geolocation.coordinates[1]
      ),
      {
        icon: this.getMarkerIcon(image),
      }
    )
      .on("click", (e) => {
        this.changeDevice(device);
      })
      .bindTooltip(
        this.getMeasurementValue(device.serial_number) as HTMLElement,
        {
          permanent: true,
          className: device.serial_number,
        }
      )
      .bindPopup(this.getPopupContent(device));
  }

  private getMarkerIcon(image) {
    const imageElement = `
            <img src=${image}
                style="
                width: 30px;
                height: 30px;
                " alt="trap"
                />`;
    return L.divIcon({
      html: `
            <div>
            ${imageElement}
            </div>
            `,
      className: "myIcon",
      iconSize: [30, 30],
    });
  }

  private getPopupContent(device: Device): string {
    return `
          <h6>${this.words.deviceInfo}</h6>
          <table>
            <tr>
              <td>${this.words.deviceName}:</td>
              <td>${device.name}</td>
            </tr>
            <tr>
              <td>${this.words.deviceSerial}:</td>
              <td>${device.serial_number}</td>
            </tr>
            <tr>
              <td>${this.words.address}:</td>
              <td>${device.address}</td>
            </tr>
            <tr>
              <td>${this.words.lastSignal}:</td>
              <td>${device.last_signal_human}</td>
            </tr>
            <tr>
              <td>${this.words.battery}:</td>
              <td>${
                device.battery ? device.battery.toFixed(1) : 0
              } <span>${$localize`Volt`}</span></td>
            </tr>
          </table>
        `;
  }

  private getMeasurementValue(serialNumber: string): string | HTMLElement {
    if (serialNumber) {
      const paramObj = this.parameterValues.find(
        (obj) => obj.serial_number === serialNumber
      );
      const suffix = this.parameters[this.selectedParameter].suffix;
      if (paramObj) {
        if (this.selectedParameter === "WindD") {
          const windIcon = this.helperService.getWindDirections(paramObj.param);
          return `<i class="${windIcon}"></i>`;
        } else {
          return Math.round(paramObj.param * 10) / 10 + " " + suffix;
        }
      }
    }
    return $localize`No`;
  }

  private changeDevice(device: Device) {
    this.map.setView(
      new LatLng(
        device.geolocation.coordinates[0],
        device.geolocation.coordinates[1]
      ),
      this.zoom
    );
    if (this.currentDevice.serial_number !== device.serial_number) {
      const currentMeteostation = this.meteostationsQuery
        .getValue()
        .meteostations.find((d) => d.serial_number === device.serial_number);
      this.meteostationsStore.update({ currentMeteostation });
    }
  }

  private generateHeatmapValues(parameterValues: any[]) {
    let values = [];
    let lats = [];
    let longs = [];
    for (let i = 0; i < parameterValues.length; i++) {
      const parameter = parameterValues[i];
      const serialNumber = parameter.serial_number;
      const device = this.devices.find((d) => d.serial_number === serialNumber);
      values.push(parameter.param);
      lats.push(device.geolocation.coordinates[0]);
      longs.push(device.geolocation.coordinates[1]);
    }
    this.drawHeatmap(lats, longs, values);
  }

  private drawHeatmap(lats: number[], longs: number[], values: number[]) {
    const model = kriging.train(values, lats, longs, "spherical", 0, 100);
    // these magic numbers are borders(approx) of Tashkent city
    for (let i = 41.15; i < 41.4; i += 0.01) {
      for (let j = 69.12; j < 69.45; j += 0.0126) {
        const predicted = kriging.predict(i, j, model);
        const corner1 = L.latLng(i, j);
        const corner2 = L.latLng(i + 0.01, j + 0.0126);
        const latLongBounds = L.latLngBounds(corner1, corner2);
        this.addRectangle(predicted, latLongBounds);
      }
    }
  }

  private addRectangle(predictedValue: number, latLongs: LatLngBounds) {
    const rectangle = L.rectangle(latLongs, {
      color: this.getMapCellColor(predictedValue) as string,
      stroke: false,
    });
    this.heatmapLayers.push(rectangle);
    rectangle.addTo(this.map);
  }

  private clearHeatmap() {
    this.heatmapLayers.forEach((l) => {
      l.remove();
    });
    this.heatmapLayers = [];
  }

  private getMapCellColor(cellValue: number) {
    const breakpoints =
      this.specialIndicatorsService.specialParameters[this.selectedParameter];
    const colorFraction = cellValue / breakpoints.max;
    const colorStops = breakpoints.stops;
    for (let i = 0; i < colorStops.length; i++) {
      if (colorFraction < colorStops[i][0]) {
        return colorStops[i][1];
      }
      if (colorStops[i + 1] !== undefined) {
        if (
          colorStops[i][0] < colorFraction &&
          colorStops[i + 1][0] > colorFraction
        ) {
          const colorStop1 = (colorStops[i][0] as number) * breakpoints.max;
          const colorStop2 = (colorStops[i + 1][0] as number) * breakpoints.max;
          const smartFraction =
            (cellValue - colorStop1) / (colorStop2 - colorStop1);
          return this.hexToRGBService.findColor(
            colorStops[i][1] as string,
            colorStops[i + 1][1] as string,
            smartFraction
          );
        }
      } else {
        return colorStops[i][1];
      }
    }
  }
}
