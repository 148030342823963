import { Injectable } from "@angular/core";
import * as Highcharts from "highcharts";
import * as ChartModuleMore from "highcharts/highcharts-more";
import HCSoldGauge from "highcharts/modules/solid-gauge";
import { ComputingAQIService } from "./computing-aqi.service";
import { MeteostationsQuery } from "../../../store/meteostations/state";
import { BehaviorSubject } from "rxjs";
import { Parameter } from "../meteostation-parameters";

// @ts-ignore
ChartModuleMore(Highcharts);
HCSoldGauge(Highcharts);

@Injectable({
  providedIn: "root",
})
export class SpecialIndicatorsService {
  public descriptions;
  public selectedParameter = new BehaviorSubject<Parameter>(null);
  private parameters;
  private hasDust = false;
  private readonly dustStopsPM1 = {
    stops: [
      [0.025, "#55bf3a"],
      [0.075, "#f3d306"],
      [0.125, "#f68c03"],
      [0.35, "#f6031f"],
      [0.5, "#8F3F97"],
      [1, "#6b0d15"],
    ],
    min: 0,
    max: 400,
  };
  private readonly dustStopsPM2_5 = {
    stops: [
      [0.024, "#55bf3a"],
      [0.07, "#f3d306"],
      [0.11, "#f68c03"],
      [0.3, "#f6031f"],
      [0.5, "#8F3F97"],
      [1, "#6b0d15"],
    ],
    min: 0,
    max: 500,
  };
  private readonly dustStopsPM10 = {
    stops: [
      [0.09, "#55bf3a"],
      [0.25, "#f3d306"],
      [0.42, "#f68c03"],
      [0.59, "#f6031f"],
      [0.7, "#8F3F97"],
      [1, "#6b0d15"],
    ],
    min: 0,
    max: 600,
  };
  private readonly gasStops = {
    stops: [
      [0.1, "#55bf3a"],
      [0.2, "#f3d306"],
      [0.4, "#f68c03"],
      [0.5, "#f6031f"],
      [0.7, "#8F3F97"],
      [1, "#6b0d15"],
    ],
    min: 0,
    max: 10,
  };
  public specialParameters = {
    "PM1.0": this.dustStopsPM1,
    "PM2.5": this.dustStopsPM2_5,
    PM10: this.dustStopsPM10,
    AlphaPM1_0: this.dustStopsPM1,
    AlphaPM2_5: this.dustStopsPM2_5,
    AlphaPM10: this.dustStopsPM10,
    RikaPM1_0: this.dustStopsPM1,
    RikaPM2_5: this.dustStopsPM2_5,
    RikaPM10: this.dustStopsPM10,
    NovaPM2_5: this.dustStopsPM2_5,
    NovaPM10: this.dustStopsPM10,
    GAS_NO2: this.gasStops,
    GAS_CO: this.gasStops,
    GAS_NH3: this.gasStops,
    GAS_SO2: this.gasStops,
    GAS_H2S: this.gasStops,
    GAS_O3: this.gasStops,
    // src: https://www.dhs.wisconsin.gov/chemical/carbondioxide.htm
    CO2: {
      field: "CO2",
      stops: [
        [0.15, "#55bf3a"],
        [0.3, "#f3d306"],
        [0.45, "#f68c03"],
        [0.6, "#f6031f"],
        [0.75, "#8F3F97"],
        [1, "#6b0d15"],
      ],
      min: 0,
      max: 5000,
    },
    AQI: {
      field: "AQI",
      stops: [
        [0.1, "#55bf3a"],
        [0.2, "#f3d306"],
        [0.3, "#f68c03"],
        [0.4, "#f6031f"],
        [0.6, "#8F3F97"],
        [1, "#6b0d15"],
      ],
      min: 0,
      max: 500,
    },
    co: {
      field: "CO",
      stops: [
        [0.08, "#55bf3a"],
        [0.18, "#f3d306"],
        [0.24, "#f68c03"],
        [0.3, "#f6031f"],
        [0.6, "#8F3F97"],
        [1, "#6b0d15"],
      ],
      min: 0,
      max: 50.4,
    },
    so2: {
      field: "SO2",
      stops: [
        [0.035, "#55bf3a"],
        [0.075, "#f3d306"],
        [0.18, "#f68c03"],
        [0.3, "#f6031f"],
        [0.6, "#8F3F97"],
        [1, "#6b0d15"],
      ],
      min: 0,
      max: 1004,
    },
    no2: {
      field: "NO2",
      stops: [
        [0.025, "#55bf3a"],
        [0.048, "#f3d306"],
        [0.175, "#f68c03"],
        [0.316, "#f6031f"],
        [0.609, "#8F3F97"],
        [1, "#6b0d15"],
      ],
      min: 0,
      max: 2049,
    },
  };

  constructor(
    private computingAQIService: ComputingAQIService,
    private meteostationsQuery: MeteostationsQuery
  ) {}

  public setParameters(sensorType: string = "Regular") {
    this.meteostationsQuery.select("parameters").subscribe((parameters) => {
      if (parameters) {
        this.parameters = Object.values(parameters).filter((param) => {
          if (param.isSpecialReading) {
            const field = param.field;
            if (param.field.includes("PM")) {
              this.hasDust = true;
            }

            // check for dust sensor type
            if (
              field.includes("Alpha") ||
              field.includes("Nova") ||
              field.includes("Rika")
            ) {
              return field.includes(sensorType);
            } else {
              return true;
            }
          }
        });
      }
    });
  }

  public generateGauges(meteostationData, sensorType: string) {
    this.setParameters(sensorType);
    const gauges = [];
    const dustSensors = {
      Regular: {
        PM2_5: "PM2.5",
        PM10: "PM10",
      },
      Alpha: {
        PM2_5: "AlphaPM2_5",
        PM10: "AlphaPM10",
      },
      Rika: {
        PM2_5: "RikaPM2_5",
        PM10: "RikaPM10",
      },
      Nova: {
        PM2_5: "NovaPM2_5",
        PM10: "NovaPM10",
      },
    };
    const dustNaming = dustSensors[sensorType];
    let lastReading = meteostationData[meteostationData.length - 1];
    for (const param of this.parameters) {
      let lastValue = lastReading[param.field];
      if (param.field === "AQI") {
        if (this.hasDust) {
          const aqi = this.calculateAQI(meteostationData, dustNaming);
          const pm2_5 = lastReading[dustNaming["PM2_5"]];
          this.descriptions = this.computingAQIService.getAQIDescriptions(
            aqi,
            pm2_5
          );
        } else {
          this.descriptions = this.computingAQIService.getAQIDescriptions(0);
        }
      } else {
        let pow10 = 10 ** param.fraction;
        lastValue = Math.round(lastValue * pow10) / pow10;
        const gauge = this.renderGauge(lastValue, param);
        gauges.push(gauge);
      }
    }
    return gauges;
  }

  public calculateAQI(meteostationData, dustNaming): number {
    // take last 8 datapoints to calculate AQI
    const dustReadingsPM_2_5 = [];
    const dustReadingsPM_10 = [];
    const lastNDatapoints = [...meteostationData].slice(-8);
    lastNDatapoints.forEach((data) => {
      const pm2_5 = data[dustNaming["PM2_5"]];
      const pm10 = data[dustNaming["PM10"]];
      if (pm2_5 !== null) {
        dustReadingsPM_2_5.push(pm2_5);
      }

      if (pm10 !== null) {
        dustReadingsPM_10.push(pm10);
      }
    });
    const aqiPM_2_5 = this.computingAQIService.getAQIndex(
      dustReadingsPM_2_5,
      dustNaming["PM2_5"]
    );
    const aqiPM_10 = this.computingAQIService.getAQIndex(
      dustReadingsPM_10,
      dustNaming["PM10"]
    );
    return Math.max(aqiPM_2_5, aqiPM_10);
  }

  private renderGauge(lastReading: number, parameters) {
    const gaugeOptions = {
      chart: {
        reflow: true,
        width: null,
        type: "solidgauge",
        borderRadius: 5,
        backgroundColor: "#eee",
      },
      credits: {
        text: "amudar.io",
        href: "https://amudar.io",
      },
      title: {
        text: parameters.caption,
        style: {
          fontSize: "14px",
          fontWeight: "bold",
        },
      },
      pane: {
        startAngle: -90,
        endAngle: 90,
        size: "140%",
        center: ["50%", "80%"],
        background: [
          {
            backgroundColor: "#d3e1ef",
            innerRadius: "60%",
            outerRadius: "100%",
            shape: "arc",
          },
        ],
      },
      navigation: {
        buttonOptions: {
          enabled: false,
        },
      },
      series: [
        {
          data: [lastReading],
          type: "solidgauge",
          name: parameters.caption,
          tooltip: {
            valueSuffix: " " + parameters.suffix,
          },
          dataLabels: {
            align: "center",
            borderWidth: 0,
            y: -30,
            formatter: () => {
              if (parameters.field === "AQI") {
                const description =
                  this.computingAQIService.getIndexDescription(lastReading);
                return `<div style="color:#339; font-size: 14px; text-align: center;">${description} <span style="font-size: 12px;">(${lastReading})</span></div><br/>`;
              } else {
                const description =
                  this.computingAQIService.getDescriptionFor1h(
                    parameters.field,
                    lastReading
                  );
                return `<div style="color:#339; font-size: 14px; text-align: center;">${description} <span style="font-size: 12px;">(${lastReading})</span></div><br/>`;
              }
            },
          },
        },
      ],
      yAxis: {
        labels: {
          distance: -20,
        },
        stops: this.specialParameters[parameters.field].stops,
        min: this.specialParameters[parameters.field].min,
        max: this.specialParameters[parameters.field].max,
        plotBands: [],
        minorTickLength: 0,
        tickLength: 0,
        title: {
          text: undefined,
        },
      },
    };

    return gaugeOptions;
  }
}
